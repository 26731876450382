<!-- Created by henian.xu on 2018/8/7. -->

<template>
    <linker
        class="store-goods-item"
        :class="{'double-row':doubleRow}"
        :to="`/store/${storeId}/goodsDetail/${data.id}`"
    >
        <div class="img-obj">
            <div
                class="inner"
                :style="`background-image: url(${$options.filters.imgCropping(data.mainImageUrl,{width:200})});`"
            />
        </div>
        <div class="inner">
            <div class="label">
                {{ data.goodsName }}
            </div>
            <div class="sub-title">
                {{ data.goodsSubtitle }}
            </div>
            <dl>
                <dt>
                    <div class="price">
                        {{ data.price | price }}
                        <!--<span class="price-old">市场价:<span>999</span></span>-->
                    </div>
                </dt>
                <dd>
                    <StoreAddCart :data="data" />
                    <!--<store-add-cart :goods="data"></store-add-cart>-->
                </dd>
            </dl>
        </div>
    </linker>
</template>

<script>
import StoreAddCart from './_storeAddCart';
import storeMixin from './storeMixin';

export default {
    name: 'StoreGoodsItem',
    mixins: [storeMixin],
    components: { StoreAddCart },
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        doubleRow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.store-goods-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: $padding;

    + .store-goods-item {
        border-top: 1px solid $color-border;
    }

    > .img-obj {
        flex: 0 0 auto;
        background-color: #fff;
        > .inner {
            position: relative;
            background-size: cover;
            background-position: center;
            width: 1.6rem;
            height: 1.6rem;
        }
    }
    > .inner {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        margin-left: $margin;
        background-color: #fff;

        > .label {
            flex: 0 0 auto;
            font-size: 0.3rem;
            line-height: 1.2;
            width: 100%;
            display: block;
            @include text-line(2);
            min-height: ($body-font-size * 1.2 * 2);
        }

        > .sub-title {
            flex: 0 0 auto;
            width: 100%;
            display: -webkit-box;
            white-space: normal;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: $gray6;
            //min-height: ($body-font-size * 1);
        }

        > dl {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            > dt {
                flex: 1 1 auto;
            }
            > dd {
                flex: 0 0 auto;
            }
        }
    }

    &.double-row {
        flex-direction: column;
        flex: 0 0 auto;
        min-width: initial;
        width: 50%;
        padding: $padding/2;
        + .store-goods-item {
            border-top: 0;
        }

        > .img-obj {
            > .inner {
                width: 100%;
                height: 0;
                padding-top: 100%;
            }
        }
        > .inner {
            margin-left: 0;
            padding: $padding/2;
            > dl {
                margin-top: $margin-small;
            }
        }
    }
}
</style>
