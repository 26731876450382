<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
            >
                <FormItem
                    prop="storeAdImage"
                    label="门　　眉:"
                >
                    <div class="w-100per">
                        <div class="tc-g6">
                            建议尺寸: 750px * 188px
                        </div>
                        <Upload
                            class="npa-a"
                            :column="3"
                            :max-files="1"
                            v-model="formModel.storeAdImage"
                            action="/rpc/comm/rs/attachment/uploadShopStoreImage"
                        />
                    </div>
                </FormItem>
                <FormItem
                    prop="storeLogo"
                    label="店铺logo:"
                >
                    <div class="w-100per">
                        <div class="tc-g6">
                            建议尺寸: 375px * 375px
                        </div>
                        <Upload
                            class="npa-a"
                            :column="3"
                            :max-files="1"
                            v-model="formModel.storeLogo"
                            action="/rpc/comm/rs/attachment/uploadShopStoreImage"
                        />
                    </div>
                </FormItem>
                <FormItem
                    prop="customerServiceImage"
                    label="客服二维码:"
                >
                    <div class="w-100per">
                        <div class="tc-g6">
                            建议尺寸: 640px * 1000px
                        </div>
                        <Upload
                            class="npa-a"
                            :column="3"
                            :max-files="1"
                            v-model="formModel.customerServiceImage"
                            action="/rpc/comm/rs/attachment/uploadStoreCustomerServiceImage"
                        />
                    </div>
                </FormItem>
                <template v-if="storeDetail.isStoreEditSendInfo">
                    <FormItem
                        prop="sendConsignor"
                        label="发件人姓名:"
                    >
                        <XInput
                            placeholder="请输入(可不填)"
                            v-model="formModel.sendConsignor"
                        />
                    </FormItem>
                    <FormItem
                        prop="sendTel"
                        label="发件人电话:"
                    >
                        <XInput
                            placeholder="请输入(可不填)"
                            v-model="formModel.sendTel"
                        />
                    </FormItem>
                </template>
                <FormItem
                    prop="storeName"
                    label="店铺名称:"
                >
                    <XInput v-model="formModel.storeName" />
                </FormItem>
                <FormItem
                    prop="contactEmail"
                    label="店铺邮箱:"
                >
                    <XInput v-model="formModel.contactEmail" />
                </FormItem>
                <FormItem
                    prop="storeRegionId"
                    label="店铺区域:"
                >
                    <RegionPicker
                        :query-data="{type:2}"
                        v-model="formModel.storeRegionId"
                    />
                </FormItem>
                <FormItem
                    prop="storeDetailAddress"
                    label="详细地址:"
                >
                    <x-input
                        type="textarea"
                        :auto-size="{minRows:2,maxRows:4}"
                        v-model="formModel.storeDetailAddress"
                        placeholder="如街道，楼层，门牌号等"
                    />
                </FormItem>
                <FormItem
                    prop="houseNum"
                    label="门 牌 号:"
                >
                    <XInput v-model="formModel.houseNum" />
                </FormItem>
                <FormItem
                    v-if="$globalVar.userData.shopStoreType===1"
                    prop="settlementCurrency"
                    label="结算币种:"
                >
                    <DicSelector
                        label="结算币种"
                        type="currency"
                        v-model="formModel.settlementCurrency"
                        :readonly="!!formModel.id"
                    />
                </FormItem>
                <FormItem
                    v-if="$globalVar.userData.shopStoreType===1"
                    prop="shipMethod"
                    label="送货方式:"
                >
                    <DicSelector
                        label="送货方式"
                        type="shipMethod"
                        multiple
                        bitwise
                        v-model="formModel.shipMethod"
                    />
                </FormItem>
                <FormItem
                    v-if="$globalVar.userData.shopStoreType===1"
                    prop="paymentType"
                    label="付款类型:"
                >
                    <Selector
                        label="付款类型"
                        multiple
                        bitwise
                        v-model="formModel.paymentType"
                        :data="paymentTypeList"
                    />
                </FormItem>
                <!--<FormItem
                    v-if="isProxyStore"
                    label="页面风格"
                    class="column"
                    prop="storeStyle"
                >
                    <List class="w-100per">
                        <ProxyStoreStyleItem
                            v-for="item in 5"
                            :key="item"
                            :data="{id:item}"
                            v-model="formModel.storeStyle"
                        />
                    </List>
                </FormItem>-->
            </XForm>
        </container>
        <PageBar>
            <Button
                theme="main"
                @click="onSubmit"
            >
                提交
            </Button>
        </PageBar>
    </Page>
</template>

<script>
import { Buyer } from '@/api';
import storeMixin from '@/mixin/storeMixin';

export default {
    name: 'StoreEdit',
    mixins: [storeMixin],
    data() {
        return {
            formModel: {
                id: '',
                storeName: '', //门店名称
                storeLogo: [], //门店logo
                storeAdImage: [], //门店广告
                storeDesc: '', //门店描述
                storeRegionId: '', //地区ID
                storeDetailAddress: '', //详细地址
                houseNum: '', //门牌号
                contactEmail: '', //邮箱地址
                settlementCurrency: '', //结算币种
                shipMethod: '', //送货方式
                paymentType: '', //付款类型
                storeStyle: '', //店铺风格
                customerServiceImage: [], //客服二维码
                sendConsignor: '',
                sendTel: '',
            },
            formRules: {
                //门店名称
                storeName: {
                    required: true,
                    message: '店铺名称不能为空',
                },
                //门店logo
                storeLogo: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '店铺logo不能为空',
                },
                //门店广告
                storeAdImage: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '门眉不能为空',
                },
                //客服二维码
                customerServiceImage: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '客服二维码不能为空',
                },
                // storeDesc: '', //门店描述
                //地区ID
                storeRegionId: {
                    required: true,
                    message: '区域不能为空',
                },
                //详细地址
                storeDetailAddress: {
                    required: true,
                    message: '详细地址不能为空',
                },
                //门牌号
                houseNum: {
                    required: true,
                    message: '门牌号不能为空',
                },
                //邮箱地址
                contactEmail: [
                    {
                        required: true,
                        message: '邮箱不能为空',
                    },
                    {
                        validator: this.$utils.Valid.email,
                        message: '请输入正确的邮箱',
                    },
                ],
                //结算币种
                settlementCurrency: {
                    required: true,
                    message: '请选择结算币种',
                },
                //送货方式
                shipMethod: {
                    required: true,
                    message: '请选择送货方式',
                },
                //付款类型
                paymentType: {
                    required: true,
                    message: '请选择付款类型',
                },
                sendConsignor: {
                    required: true,
                    message: '不能为空',
                },
                /*sendTel: {
                    required: true,
                    message: '不能为空',
                },*/
            },
            storeDetail: {},
            paymentTypeList: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        Promise.all([Buyer.Sp.ShopStore.getShopStoreDetail(), Buyer.Sp.ShopStore.getShopStorePaymentTypeList()])
            .then(jsons => {
                const detail = jsons[0].data.data || {};
                const paymentTypeList = jsons[1].data.data || [];
                next(vm => {
                    vm.paymentTypeList = paymentTypeList;
                    Object.keys(vm.formModel).forEach(key => {
                        if (key === 'storeLogo') {
                            vm.formModel[key] = detail.storeLogo
                                ? [
                                      {
                                          name: detail.storeLogo,
                                          address: detail.storeLogoUrl,
                                      },
                                  ]
                                : [];
                        } else if (key === 'storeAdImage') {
                            vm.formModel[key] = detail.storeAdImage
                                ? [
                                      {
                                          name: detail.storeAdImage,
                                          address: detail.storeAdImageUrl,
                                      },
                                  ]
                                : [];
                        } else if (key === 'customerServiceImage') {
                            vm.formModel[key] = detail.customerServiceImage
                                ? [
                                      {
                                          name: detail.customerServiceImage,
                                          address: detail.customerServiceImageUrl,
                                      },
                                  ]
                                : [];
                        } else {
                            vm.formModel[key] = detail[key] || vm.formModel[key];
                        }
                    });
                    vm.storeDetail = detail;
                });
            })
            .catch(() => next());
    },
    methods: {
        buildFormModel() {
            const model = JSON.parse(JSON.stringify(this.formModel));
            model.storeLogo = model.storeLogo.reduce((p, c) => (p.push(c.name), p), []).join(',');
            model.storeAdImage = model.storeAdImage.reduce((p, c) => (p.push(c.name), p), []).join(',');
            model.customerServiceImage = model.customerServiceImage.reduce((p, c) => (p.push(c.name), p), []).join(',');
            return model;
        },
        onSubmit() {
            // this.$router.push(`/store/${this.storeUserName}/home`);
            this.$refs['form'].validate().then(() => {
                console.log(this.buildFormModel());
                this.$api.Buyer.Sp.ShopStore.saveShopStore(this.buildFormModel()).then(json => {
                    const res = json.data;
                    this.$router.go(-1);
                    this.$messageBox.tips(res.msg);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
