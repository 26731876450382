<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
            >
                <FormItem
                    prop="activityName"
                    label="活动名称"
                >
                    <XInput v-model="formModel.activityName" />
                </FormItem>
                <FormItem
                    prop="activityBeginTime"
                    label="开始时间"
                >
                    <Datetime
                        format="YYYY-MM-DD HH:mm"
                        placeholder="请选择..."
                        v-model="formModel.activityBeginTime"
                    />
                </FormItem>
                <FormItem
                    prop="activityEndTime"
                    label="结束时间"
                >
                    <Datetime
                        format="YYYY-MM-DD HH:mm"
                        placeholder="请选择..."
                        v-model="formModel.activityEndTime"
                    />
                </FormItem>
                <FormItem
                    prop="activityNotice"
                    label="活动须知"
                >
                    <XInput
                        type="textarea"
                        :auto-size="{minRows:2,maxRows:4}"
                        v-model="formModel.activityNotice"
                    />
                </FormItem>
                <!--<FormItem
                    prop="activityCover"
                    label="活动封面:">
                    <Upload
                        :column="3"
                        :max-files="1"
                        v-model="formModel.activityCover"
                        action="/rpc/comm/rs/attachment/uploadStoreActivityImage"/>
                </FormItem>
                <FormItem
                    prop="activityDesc"
                    label="活动描述">
                    <XInput
                        type="textarea"
                        :auto-size="{minRows:2,maxRows:4}"
                        v-model="formModel.activityDesc"/>
                </FormItem>-->
                <FormItem
                    prop="paymentType"
                    label="付款类型:"
                >
                    <Selector
                        label="付款类型"
                        multiple
                        bitwise
                        tiling
                        v-model="formModel.paymentType"
                        :data="paymentTypeList"
                    />
                </FormItem>
                <FormItem
                    prop="shipMethod"
                    label="送货方式:"
                >
                    <Selector
                        label="送货方式"
                        multiple
                        bitwise
                        tiling
                        v-model="formModel.shipMethod"
                        :data="shipMethodList"
                    />
                </FormItem>
                <Panel
                    v-if="formModel.shipMethod & 2"
                    label="自取信息"
                >
                    <FormItem
                        prop="pickupServiceStation"
                        label="自取门店:"
                    >
                        <Selector
                            label="自取门店"
                            multiple
                            v-model="formModel.pickupServiceStation"
                            :data="serviceStation"
                        />
                    </FormItem>
                    <FormItem
                        prop="pickupDate"
                        label="自取时间:"
                    >
                        <Datetime
                            format="YYYY-MM-DD"
                            placeholder="请选择..."
                            v-model="formModel.pickupDate"
                        />
                    </FormItem>
                </Panel>
                <template v-if="formModel.shipMethod & 1">
                    <Panel label="送货上门区域">
                        <FormItem
                            class="npa-a"
                            prop="activityFreightJson"
                        >
                            <StoreFreightPicker v-model="formModel.activityFreightJson" />
                        </FormItem>
                    </Panel>
                    <Panel label="送货信息">
                        <FormItem
                            prop="deliverBeginDate"
                            label="送货上门开始时间:"
                        >
                            <Datetime
                                format="YYYY-MM-DD"
                                placeholder="请选择..."
                                v-model="formModel.deliverBeginDate"
                            />
                        </FormItem>
                        <FormItem
                            prop="deliverEndDate"
                            label="送货上门结束时间:"
                        >
                            <Datetime
                                format="YYYY-MM-DD"
                                placeholder="请选择..."
                                v-model="formModel.deliverEndDate"
                            />
                        </FormItem>
                        <FormItem
                            prop="deliverDesc"
                            label="送货上门描述:"
                        >
                            <XInput
                                type="textarea"
                                :auto-size="{minRows:2,maxRows:4}"
                                v-model="formModel.deliverDesc"
                            />
                        </FormItem>
                    </Panel>
                </template>
                <FormItem
                    prop="isForbidCancelOrder"
                    label="是否可取消订单:"
                >
                    <Checkbox v-model="formModel.isForbidCancelOrder" />
                </FormItem>
                <FormItem
                    prop="isAvailable"
                    label="是否启用:"
                >
                    <Checkbox v-model="formModel.isAvailable" />
                </FormItem>
                <Panel label="活动商品">
                    <FormItem
                        class="npa-a"
                        prop="activityGoodsJson"
                    >
                        <StoreGoodsPicker v-model="formModel.activityGoodsJson" />
                    </FormItem>
                </Panel>
            </XForm>
        </container>
        <PageBar>
            <Button
                @click="onSubmit"
                theme="main"
                label="提交"
            />
        </PageBar>
    </Page>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';
import { Buyer } from '@/api';

export default {
    name: 'StoreAddActivity',
    mixins: [storeMixin],
    data() {
        return {
            formModel: {
                id: '',
                activityName: '', //活动名称
                activityBeginTime: this.$moment().format('YYYY-MM-DD HH:mm'), //开始时间
                activityEndTime: '', //结束时间
                activityNotice: '', //活动须知
                activityCover: [], //活动封面
                activityDesc: '', //活动描述
                shipMethod: 0, //送货方式
                paymentType: 0, //付款类型
                pickupServiceStation: [], //自取门店 逗号分隔id
                pickupDate: '', //自取时间
                deliverBeginDate: '', //送货上门开始时间
                deliverEndDate: '', //送货上门结束时间
                deliverDesc: '', //送货上门描述
                isForbidCancelOrder: true, //是否可取消订单
                isAvailable: true, //是否可用
                activityFreightJson: [], //送货上门区域设置json
                activityGoodsJson: [], //活动商品json
            },
            formRules: {
                activityName: {
                    required: true,
                    message: '活动名称不能为空',
                }, //活动名称
                activityBeginTime: {
                    required: true,
                    message: '开始时间不能为空',
                }, //开始时间
                activityEndTime: {
                    required: true,
                    message: '结束时间不能为空',
                }, //结束时间
                // activityNotice: '活动须知', //活动须知
                activityCover: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '至少要有一张封面图',
                }, //活动封面
                // activityDesc: '活动描述', //活动描述
                shipMethod: {
                    required: true,
                    message: '送货方式不能为空',
                }, //送货方式
                paymentType: {
                    required: true,
                    message: '付款类型不能为空',
                }, //付款类型
                pickupServiceStation: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '至少要有一个自取点',
                }, //自取门店 逗号分隔id
                pickupDate: {
                    required: true,
                    message: '自取时间不能为空',
                }, //自取时间
                deliverBeginDate: {
                    required: true,
                    message: '送货开始时间不能为空',
                }, //送货上门开始时间
                deliverEndDate: {
                    required: true,
                    message: '送货结束时间不能为空',
                }, //送货上门结束时间
                // deliverDesc: '送货上门描述', //送货上门描述
                activityFreightJson: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '至少要有一个区域',
                }, //送货上门区域设置json
                activityGoodsJson: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '至少要有一个商品',
                }, //活动商品json
            },
            shipMethodList: [],
            paymentTypeList: [],
            serviceStation: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        const activityId = to.params.id;
        const requestList = [
            Buyer.Sp.ShopStore.getStoreShipMethodList(),
            Buyer.Sp.ShopStore.getStorePaymentTypeList(),
            Buyer.Sp.ShopStoreServiceStation.getServiceStationList(),
        ];
        activityId && requestList.push(Buyer.Sp.ShopStoreActivity.shopStoreActivityDetail(activityId));
        Promise.all(requestList)
            .then(jsons => {
                next(vm => {
                    vm.shipMethodList = jsons[0].data.data;
                    vm.paymentTypeList = jsons[1].data.data;
                    vm.serviceStation = jsons[2].data.data.map(item => ({
                        ...item,
                        desc: item.stationRegionName,
                    }));
                    if (jsons[3]) {
                        const res = jsons[3].data.data;
                        const {
                            shopStoreServiceStationList,
                            storeActivity,
                            storeActivityFreightList,
                            storeActivityGoodsList,
                        } = res;
                        const { formModel } = vm;
                        Object.keys(formModel).forEach(key => {
                            if (key === 'activityCover' && storeActivity.activityCover) {
                                formModel.activityCover = [
                                    {
                                        name: storeActivity.activityCover,
                                        address: storeActivity.activityCoverUrl,
                                    },
                                ];
                            } else if (
                                /(activityBeginTime|activityEndTime|deliverBeginDate|deliverEndDate|pickupDate)/.test(
                                    key,
                                )
                            ) {
                                formModel[key] = vm.$moment(storeActivity[key]).format('YYYY-MM-DD HH:mm');
                            } else {
                                formModel[key] = storeActivity[key] === undefined ? formModel[key] : storeActivity[key];
                            }
                        });
                        formModel.activityFreightJson = storeActivityFreightList;
                        formModel.activityGoodsJson = storeActivityGoodsList;
                        formModel.pickupServiceStation = shopStoreServiceStationList.reduce(
                            (p, c) => (p.push(c.id), p),
                            [],
                        );
                    }
                });
            })
            .catch(() => next());
    },
    computed: {
        isCopy() {
            return +this.$route.query.copy;
        },
    },
    methods: {
        buildForm() {
            const model = JSON.parse(JSON.stringify(this.formModel));

            if (this.isCopy) {
                delete model.id;
                model.activityFreightJson.forEach(item => {
                    delete item.id;
                });
                model.activityGoodsJson.forEach(item => {
                    delete item.id;
                    item.skuList.forEach(sku => {
                        delete sku.id;
                    });
                });
            }

            model.activityBeginTime = this.$moment(model.activityBeginTime).format('x');
            model.activityEndTime = this.$moment(model.activityEndTime).format('x');
            model.deliverBeginDate = this.$moment(model.deliverBeginDate).format('x');
            model.deliverEndDate = this.$moment(model.deliverEndDate).format('x');
            model.pickupDate = this.$moment(model.pickupDate).format('x');
            model.activityFreightJson = JSON.stringify(model.activityFreightJson);
            model.activityCover = model.activityCover.reduce((p, c) => (p.push(c.name), p), []).join(',');
            model.activityGoodsJson = JSON.stringify(
                model.activityGoodsJson.map((goods, index) => ({ ...goods, orderNum: index })),
            );
            console.log(model);
            return model;
        },
        onSubmit() {
            this.$refs['form'].validate().then(() => {
                this.$api.Buyer.Sp.ShopStoreActivity.saveShopStoreActivity(this.buildForm()).then(json => {
                    const res = json.data;
                    this.$messageBox.tips(res.msg);
                    this.$router.go(-1);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
