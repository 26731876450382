<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <List>
                <StoreGoodsItem
                    :to="`./addGoods/${item.id}`"
                    v-for="(item) in goodsList"
                    :key="item.id"
                    :data="item"
                />
            </List>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
        <PageBar>
            <Button
                to="./addGoods"
                theme="main"
                label="发布商品"
            />
        </PageBar>
    </Page>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'StoreGoods',
    mixins: [storeMixin, infiniteLoading],
    data() {
        return {
            goodsList: [],
        };
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Sp.ShopStoreGoods.goodsList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.goodsList = this.goodsList.concat(res.data);
                return Promise.resolve(json);
            });
        },
    },
    activated() {
        this.infiniteDisabled = true;
        this.goodsList = [];
        this.pagination = {};
        this.$nextTick(() => {
            this.infiniteDisabled = false;
        });
    },
};
</script>

<style lang="scss">
</style>
