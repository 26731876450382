<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <tabs
            class="average"
            @tabChange="onTabsChange"
        >
            <tab-panel
                label="全部"
                :sign="0"
                :active-panel="+shipMethod === 0"
            />
            <tab-panel
                label="送货上门"
                :sign="1"
                :active-panel="+shipMethod === 1"
            />
            <tab-panel
                label="门店自取"
                :sign="2"
                :active-panel="+shipMethod === 2"
            />
        </tabs>
        <container ref="container">
            <List>
                <StoreOrderItem
                    v-for="(item,index) in orderList"
                    :key="item.id"
                    :data="item"
                    @delete="onItemDelete(item,index)"
                    @update="onUpdate($event,index)"
                />
            </List>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
        <PageBar>
            <Button
                label="商品统计"
                theme="second"
                :to="`../../goodsStat/${shipMethod}?activityId=${activityId}`"
            />
            <Button
                label="送货清单"
                theme="main"
                @click="onExcel"
            />
        </PageBar>
    </Page>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'ManagementActivity',
    mixins: [storeMixin, infiniteLoading],
    data() {
        return {
            orderList: [],
        };
    },
    computed: {
        activityId() {
            return this.$route.params.activityId;
        },
        shipMethod() {
            return this.$route.params.shipMethod;
        },
    },
    beforeRouteEnter(to, from, next) {
        const { activityId, shipMethod } = to.params;
        if (shipMethod === undefined) {
            next({
                ...to,
                params: {
                    activityId,
                    shipMethod: 0,
                },
            });
        }
        next();
    },
    methods: {
        getListData({ storeActivityId = this.activityId, shipMethod = this.shipMethod, currentPage, pageSize } = {}) {
            return this.$api.Buyer.Sp.ShopStoreOrder.orderList({
                storeActivityId,
                shipMethod,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.orderList = this.orderList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        refreshList() {
            this.infiniteDisabled = true;
            this.pagination = {};
            this.orderList = [];
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });
        },
        onTabsChange(item) {
            const { activityId } = this;
            this.$router.replace(
                {
                    ...this.$route,
                    params: {
                        activityId,
                        shipMethod: item.sign,
                    },
                },
                this.refreshList,
            );
        },
        onUpdate(data, index) {
            this.orderList.splice(index, 1, data);
        },
        onExcel() {
            return this.$api.Buyer.Sp.ShopStoreOrder.exportOrderExcel({
                storeActivityId: this.activityId,
                // orderStatus,
                shipMethod: this.shipMethod,
            });
        },
    },
};
</script>

<style lang="scss">
.activity-header {
    padding: $padding $padding 0 $padding;
    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        background-color: $color-second;
        color: #fff;
        border-top-left-radius: $padding-small;
        border-top-right-radius: $padding-small;
    }
}
</style>
