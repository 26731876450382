<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page :title="goods.name||pageData.title">
        <container ref="container">
            <div class="goods-swiper">
                <ad-swiper
                    is-images-background
                    :data="imgList"
                />
                <!--<div class="sold-out"
                     v-if="!goods.showStock"
                ></div>-->
            </div>

            <div class="goods-info">
                <div class="body">
                    <div class="label-wrap">
                        <div class="name">
                            {{ goods.name }}
                        </div>
                        <div
                            class="desc"
                            v-if="goods.desc"
                        >
                            {{ goods.desc }}
                        </div>
                    </div>
                    <div
                        class="share"
                        :class="isCollect && 'active'"
                        @click="onCollect"
                    >
                        <i
                            class="f-icon"
                            v-if="isCollect"
                        >&#xf03b;</i>
                        <i
                            class="f-icon"
                            v-else
                        >&#xf03a;</i>
                        <div class="label">
                            {{ isCollect?'取消':'' }}收藏
                        </div>
                    </div>
                    <div
                        class="share active"
                        v-if="!!shoeSize"
                    >
                        <i class="f-icon">&#xf073;</i>
                        <div class="label">
                            推荐 <span class="badge badge-main">{{ shoeSize }}</span> 码
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="price fs-big">
                        {{ goods.price }}
                        <span
                            v-if="goods.marketPrice"
                            class="old"
                        >{{ goods.marketPrice }}</span>
                    </div>
                    <div
                        v-if="goodsSales"
                        class="stock tc-g6"
                    >
                        销量：{{ goodsSales }}
                    </div>
                    <!--<div class="stock tc-g6">
                        库存：{{ goods.showStock }}
                    </div>-->
                </div>
            </div>

            <tabs class="average ma-t">
                <tab-panel label="商品详情">
                    <div
                        class="pa-a bc-w"
                        style="line-height: 0;"
                    >
                        <img
                            v-for="(item,index) in goods.detailImageList"
                            :key="index"
                            :src="item"
                            width="100%"
                        >
                    </div>
                </tab-panel>
            </tabs>
        </container>
        <div class="footer pa-r">
            <linker
                :to="`/store/${this.storeId}/home`"
                class="btn cart"
            >
                <div>
                    <i class="f-icon fs-more">&#Xf006;</i>
                    <div class="fs-small">
                        店铺首页
                    </div>
                </div>
            </linker>

            <div>
                <span class="tc-g6">已选购</span>
                <StoreAddCart :data="goods" />
            </div>
        </div>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import storeMixin from './storeMixin';
import StoreAddCart from './_storeAddCart';
import { Buyer } from '@/api';

export default {
    name: 'GoodsDetail',
    mixins: [pageMixin, storeMixin],
    components: { StoreAddCart },
    data() {
        return {
            goods: {},
            address: {},
            freightData: {},
            collectGoods: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Sp.ShopStoreGoods.goodsDetail({
            storeId: to.params.storeId,
            id: to.params.id,
        })
            .then(json => {
                const res = json.data.data;
                const { goods, buyerDeliveryAddress: address, collectGoods } = res;
                next(vm => {
                    vm.goods = goods;
                    vm.collectGoods = collectGoods || {};
                    vm.address = address || {};
                    vm.setShareData({
                        title: goods.goodsName,
                        desc: goods.goodsSubtitle,
                        imgUrl: goods.mainImageUrl,
                        link: to.fullPath,
                        successData: {
                            urllinkType: 1,
                            objectId: goods.id,
                        },
                    });
                });
            })
            .catch(() => {
                next();
            });
    },
    computed: {
        imgList() {
            if (!this.goods.imgList || !this.goods.imgList.length) return [];
            return this.goods.imgList.map(item => {
                return {
                    imgUrl: item,
                };
            });
        },
        freight() {
            const { freightData } = this;
            if (!this.address.id) return '';
            const mode = freightData.freightMode;
            const freight = freightData.freightAmount;
            // const freeFreightOrderAmount = this.address.freeFreightOrderAmount;
            switch (mode) {
                case 1:
                    return '全场包邮';
                case 2:
                case 3:
                    return !+freight ? '免运费' : +freight === -1 ? '无法配送' : freight;
                default:
                    return '';
            }
        },
        isCollect() {
            return this.collectGoods.id;
        },
        // 销量
        goodsSales() {
            return this.goods.hasOwnProperty('goodsSales') ? this.goods.goodsSales + '' : '';
        },
    },
    methods: {
        onBuyTip() {
            if (this.goods.buyLimitType !== 0) {
                this.$messageBox.tips(this.goods.buyLimitMsg);
            }
        },
        onCollect() {
            if (!this.isCollect) {
                this.$api.Buyer.Mb.CollectGoods.collectGoods({
                    goodsId: this.goods.id,
                    storeId: this.storeId,
                }).then(json => {
                    const res = json.data;
                    console.log(res);
                    this.collectGoods = res.data;
                    this.$messageBox.tips(res.msg, '温馨提示');
                });
            } else {
                this.$api.Buyer.Mb.CollectGoods.delCollectGoods({
                    ids: this.isCollect,
                }).then(json => {
                    const res = json.data;
                    console.log(res);
                    this.collectGoods = {};
                    this.$messageBox.tips(res.msg, '温馨提示');
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    background-color: #fff;
    > .cart {
        color: $gray6;
        flex: 0 0 auto;
        width: auto;
    }
}
</style>
<style lang="scss">
.goods-swiper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    > .swiper-container {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    > .sold-out {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon01.png') center no-repeat;
        background-size: 70%;
        width: 100%;
        height: 100%;
    }
}

.goods-info {
    // padding: $padding 0;
    background-color: #fff;
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        > .price-wrap,
        > .countdown-wrap {
            min-height: 1rem;
            padding: 0 $padding;
        }
        > .price-wrap {
            flex: 0.54 0.54 1%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: $color-main;
            .price {
                color: #fff;
                line-height: 1.2;
            }
            > .price {
                font-size: 0.4rem;
            }
            > .old-price {
                color: #fff;
                font-size: 0.22rem;
                line-height: 1.2;
            }
        }
        > .countdown-wrap {
            flex: 0.46 0.46 1%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: $color-yellow;
            > .countdown {
                justify-content: center;
                color: $color-red;
            }
        }
    }
    > .body {
        padding: $padding-small $padding;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        > .label-wrap {
            flex: 1 1 auto;
            > .name {
                font-size: 0.32rem;
            }
            > .desc {
                color: $gray6;
                line-height: 1.2;
                margin-top: $margin;
            }
        }
        > .share {
            flex: 0 0 auto;
            padding: $padding;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 0.22rem;
            color: $gray6;
            > .f-icon {
                font-size: 0.6rem;
                line-height: 1;
                margin-bottom: $margin-small;
            }
            > .label {
                white-space: nowrap;
            }

            &.active {
                color: $color-main;
            }
        }
    }
    > .footer {
        padding: $padding-small $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
}
</style>
