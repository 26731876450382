<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <tabs
            class="average"
            @tabChange="onTabsChange"
        >
            <tab-panel
                label="全部"
                :sign="0"
                :active-panel="+orderStatus === 0"
            />
            <template v-if="shipMethod===1">
                <tab-panel
                    label="待发货"
                    :sign="20"
                    :active-panel="+orderStatus === 20"
                />
                <tab-panel
                    label="已发货"
                    :sign="30"
                    :active-panel="+orderStatus === 30"
                />
            </template>
            <tab-panel
                v-if="shipMethod===2"
                label="待自取"
                :sign="40"
                :active-panel="+orderStatus === 40"
            />
            <tab-panel
                label="已完成"
                :sign="100"
                :active-panel="+orderStatus === 100"
            />
        </tabs>
        <query-box
            v-if="shipMethod===2"
            class="second-header"
            top=".89rem"
            placeholder="请输入手机号"
            show-search-btn
            show-reset-btn
            v-model="pickupTel"
            @query="refreshList"
        />
        <container ref="container">
            <List>
                <StoreOrderItem
                    v-for="(item,index) in orderList"
                    :key="item.id"
                    :data="item"
                    @delete="onItemDelete(item,index)"
                    @update="onUpdate($event,index)"
                />
            </List>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'ManagementActivity',
    mixins: [storeMixin, infiniteLoading],
    data() {
        return {
            pickupTel: '',
            orderList: [],
        };
    },
    computed: {
        orderStatus() {
            return this.$route.params.orderStatus;
        },
        shipMethod() {
            return +this.$route.params.shipMethod;
        },
    },
    beforeRouteEnter(to, from, next) {
        const { orderStatus, shipMethod } = to.params;
        if (orderStatus === undefined) {
            next({
                ...to,
                params: {
                    shipMethod,
                    orderStatus: 0,
                },
            });
        }
        next();
    },
    methods: {
        getListData({
            orderStatus = this.orderStatus,
            shipMethod = this.shipMethod,
            pickupTel = this.pickupTel,
            currentPage,
            pageSize,
        } = {}) {
            return this.$api.Buyer.Sp.ShopStoreOrder.orderList({
                orderStatus,
                shipMethod,
                pickupTel,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.orderList = this.orderList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        refreshList() {
            this.infiniteDisabled = true;
            this.pagination = {};
            this.orderList = [];
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });
        },
        onTabsChange(item) {
            const { shipMethod } = this;
            this.$router.replace(
                {
                    ...this.$route,
                    params: {
                        orderStatus: item.sign,
                        shipMethod,
                    },
                },
                this.refreshList,
            );
        },
        onUpdate(data, index) {
            this.orderList.splice(index, 1, data);
        },
        onItemDelete(item, index) {
            this.orderList.splice(index, 1);
        },
        onExcel() {
            return this.$api.Buyer.Sp.ShopStoreOrder.exportOrderExcel({
                storeActivityId: this.activityId,
                // orderStatus,
                shipMethod: this.shipMethod,
            });
        },
    },
};
</script>

<style lang="scss">
.activity-header {
    padding: $padding $padding 0 $padding;
    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        background-color: $color-second;
        color: #fff;
        border-top-left-radius: $padding-small;
        border-top-right-radius: $padding-small;
    }
}
</style>
