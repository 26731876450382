<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <List>
                <div
                    v-for="item in statList"
                    :key="item.id"
                    class="user-item"
                >
                    <div class="avatar">
                        <div
                            class="inner"
                            :style="`background-image: url('${item.avatar||$defaultImg}');`"
                        />
                    </div>
                    <div class="inner">
                        <div class="nickname">
                            {{ item.nickName }}
                        </div>
                        <div class="mobile">
                            {{ item.mobile }}
                        </div>
                    </div>
                    <div class="extra ta-r">
                        <div class="price">
                            {{ item.orderAmount }}
                        </div>
                        <div class="fs-small tc-g6">
                            订单数: {{ item.orderNum }}
                        </div>
                    </div>
                </div>
            </List>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'ManagementActivity',
    mixins: [storeMixin, infiniteLoading],
    data() {
        return {
            statList: [],
        };
    },
    computed: {},
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Sp.ShopStoreStat.buyerOrderStatList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.statList = this.statList.concat(res.data);
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
.user-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    background-color: #fff;
    > .avatar {
        flex: 0 0 auto;
        width: 1rem;
        margin-right: $margin-small;
        > .inner {
            width: 100%;
            padding-top: 100%;
            background: transparent center no-repeat;
            border-radius: 100vw;
        }
    }
    > .inner {
        flex: 1 1 1%;
    }
    > .extra {
        flex: 0 0 auto;
    }
}
</style>
